import React, { useState, useEffect, useRef } from "react";
import { Form, Table, Input, Button, Modal, Tooltip } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import { priceFormat } from "../../utils";
export default function UserInfoPage() {
  const [dataTable, setDataTable] = useState([]);
  const [dataMoneyLog, setDataMoneyLog] = useState([]);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({
    userid: "",
    keyword: "",
  });
  const userInfoList = useRef();

  const columns = [
    {
      title: "USER ID",
      dataIndex: "userid",
      key: "userid",
      align: "center",
      width: 150,
    },
    {
      title: "TÊN ĐĂNG NHẬP",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "LỊCH SỬ CHƠI",
      dataIndex: "chip_pool",
      key: "chip_pool",
      align: "center",
      width: 120,
      render: (data, data1, index) => (
        <Button
          className="text-center"
          type="primary"
          onClick={() => {
            getMoneyLogList(data1.userid);
          }}
        >
          Xem
        </Button>
      ),
    },
    {
      title: "BOT",
      dataIndex: "bot",
      key: "bot",
      align: "center",
      render: (data) => <p>{data.toString()}</p>,
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
      width: 200,
      align: "center",
    },
    {
      title: "NỀN TẢNG",
      dataIndex: "platform",
      key: "platform",
      align: "center",
    },
    {
      title: "IS SUN",
      dataIndex: "isSun",
      key: "isSun",
      align: "center",
    },
    {
      title: "ĐĂNG NHẬP LẦN CUỐI",
      dataIndex: "lastedLogin",
      width: 200,
      key: "lastedLogin",
      align: "center",
      render: (data) => (
        <p>{moment(data * 1000).format("DD-MM-YYYY hh:mm:ss")}</p>
      ),
    },
    {
      title: "CHI TIẾT",
      dataIndex: "lastedLogin",
      width: 100,
      key: "",
      align: "center",
      render: (data, data1, index) => (
        <Button
          className="text-center"
          type="primary"
          onClick={() => {
            window.location = "./user-detail?userid=" + data1.userid;
          }}
        >
          Xem
        </Button>
      ),
    },
  ];
  const columnsMoneyLog = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 140,
      sorter: (a, b) => a.id - b.id,
      align: "center",
    },

    {
      title: "GAME",
      width: 140,
      dataIndex: "game",
      key: "game",
    },
    {
      title: "LOG",
      width: 250,
      dataIndex: "log",
      key: "log",
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
      align: "center",
    },
    {
      title: "TIỀN",
      width: 160,
      dataIndex: "gold",
      key: "gold",
      render: (data) => priceFormat(data),
    },
    {
      title: "TIỀN THẮNG/THUA",
      dataIndex: "updateGold",
      key: "updateGold",
      sorter: (a, b) => a.updateGold - b.updateGold,
      render: (data) => priceFormat(data),
      width: 200,
    },
    {
      title: "DETAIL",
      dataIndex: "detail",
      key: "detail",
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "TIME",
      dataIndex: "time",
      key: "time",
      width: 200,
      align: "center",
      sorter: (a, b) => moment(a.time).valueOf() - moment(b.time).valueOf(),
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>
      ),
    },
  ];
  async function getMoneyLogList(player) {
    try {
      const data = await APIService.moneyLogList(player, "", "");
      if (data) {
        setDataMoneyLog(data);
        setOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getUserInfoList() {
    try {
      const data = await APIService.userInfoList();
      if (data) {
        userInfoList.current = data;
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getSearchUserInfo() {
    try {
      const data = await APIService.searchUserInfo(
        filter.userid.trim(),
        filter.keyword.trim()
      );
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getUserInfoList();
  }, []);

  useEffect(() => {
    if (filter.userid.length || filter.keyword.length) {
      getSearchUserInfo();
    } else {
      setDataTable(userInfoList.current);
    }
  }, [filter]);

  return (
    <>
      <FormSearchFilter
        title="Thông tin"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          setFilter({ ...filter, ...value });
        }}
      >
        <Form.Item label="Player ID" className="form-item-filter" name="userid">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item
          label="Username / Name"
          className="form-item-filter"
          name="keyword"
        >
          <Input className="w-full" />
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} dataSource={dataTable} />

      <Modal
        title={dataMoneyLog[0]?.player}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1100}
        footer={false}
      >
        <Table columns={columnsMoneyLog} dataSource={dataMoneyLog} />
      </Modal>
    </>
  );
}
