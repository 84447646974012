import React from "react";
import "./fonts/Montserrat.ttf";
import "antd/dist/antd.min.css";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./page/error-page";
import LoginPage from "./page/login-page";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import UserInfoPage from "./page/user/user-info-page";
import UserDetailPage from "./page/user/user-detail-page";
import UserMailPage from "./page/user/user-mail-page";
import UserMoneyLogPage from "./page/user/user-moneylog-page";
import UserLoginLogPage from "./page/user/user-loginlog-page";
import UserBroadcastLogPage from "./page/user/user-broadcastlog-page";
import GameLogPage from "./page/log/log-gamelog-page";
import TrackingLogPage from "./page/log/log-trackinglog-page";
import JackbotFishLogPage from "./page/log/log-jackpotfishlog-page";
import JackbotLogPage from "./page/log/log-jackpotlog-page";
import FishLogPage from "./page/log/log-fishlog-page";
import FishLogDetailPage from "./page/log/log-fishlogdetail-page";
import SlotLogPage from "./page/log/log-slotlog-page";
import SlotLogDetailPage from "./page/log/log-slotlogdetail-page";
import ThongKeGoldPage from "./page/thongke/thongke-gold-page";
import ThongKePaymentPage from "./page/thongke/thongke-payment-page";
import ThongKeGeneralPage from "./page/thongke/thongke-general-page";
import ThongKeDAUPage from "./page/thongke/thongke-dau-page";
import ThongKeTrackingPage from "./page/thongke/thongke-tracking-page";
import ConfigGameStockPage from "./page/config/config-gamestock-page";
import ConfigJackpotLivePage from "./page/config/config-jackpotlive-page";
import ConfigJackpotPage from "./page/config/config-jackpot-page";
import ConfigUpdateGamePage from "./page/config/config-updategame-page";
import PaymentCardNapPage from "./page/payment/payment-card-nap-page";
import PaymentCardDuyetPage from "./page/payment/payment-card-duyet-page";
import PaymentCardKhoPage from "./page/payment/payment-card-kho-page";
import PaymentCardThongKeKhoPage from "./page/payment/payment-card-thongkekho-page";
import PaymentCodeAddPage from "./page/payment/payment-code-add-page";
import PaymentCodeListPage from "./page/payment/payment-code-list-page";
import PaymentCodeNapPage from "./page/payment/payment-code-nap-page";
import PaymentCodeDoiPage from "./page/payment/payment-code-doi-page";
import PaymentBankNapPage from "./page/payment/payment-bank-nap-page";
import PaymentBankDoiPage from "./page/payment/payment-bank-doi-page";
import PaymentBankDuyetPage from "./page/payment/payment-bank-duyet-page";
import PaymentMomoNapPage from "./page/payment/payment-momo-nap-page";
import PaymentMomoDoiPage from "./page/payment/payment-momo-doi-page";
import PaymentMomoDuyetPage from "./page/payment/payment-momo-duyet-page";
import ConfigPaymentPage from "./page/config/config-payment-page";
import ConfigGamePage from "./page/config/config-game-page";
import ConfigBankPage from "./page/config/config-bank-page";
import UserWithDrawSunPage from "./page/user/user-withdraw-sun-page";
import UserWithDrawHitPage from "./page/user/user-withdraw-hit-page";
import { getBaseUrlPath } from "./utils";

const router = createBrowserRouter(
  [
    {
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/user-info",
          element: <UserInfoPage />,
        },
        {
          path: "/user-detail",
          element: <UserDetailPage />,
        },
        {
          path: "/user-moneylog",
          element: <UserMoneyLogPage />,
        },
        {
          path: "/user-broadcastlog",
          element: <UserBroadcastLogPage />,
        },
        {
          path: "/user-loginlog",
          element: <UserLoginLogPage />,
        },
        {
          path: "/user-mail",
          element: <UserMailPage />,
        },
        {
          path: "/log-gamelog",
          element: <GameLogPage />,
        },
        {
          path: "/log-trackinglog",
          element: <TrackingLogPage />,
        },
        {
          path: "/log-jackpotfishlog",
          element: <JackbotFishLogPage />,
        },
        {
          path: "/log-jackpotlog",
          element: <JackbotLogPage />,
        },
        {
          path: "/log-gamelog",
          element: <GameLogPage />,
        },
        {
          path: "/log-fishlog",
          element: <FishLogPage />,
        },
        {
          path: "/log-fishlogdetail",
          element: <FishLogDetailPage />,
        },
        {
          path: "/log-slotlog",
          element: <SlotLogPage />,
        },
        {
          path: "/log-slotlogdetail",
          element: <SlotLogDetailPage />,
        },
        {
          path: "/thongke-gold",
          element: <ThongKeGoldPage />,
        },
        {
          path: "/thongke-payment",
          element: <ThongKePaymentPage />,
        },
        {
          path: "/thongke-general",
          element: <ThongKeGeneralPage />,
        },
        {
          path: "/thongke-dau",
          element: <ThongKeDAUPage />,
        },
        {
          path: "/thongke-tracking",
          element: <ThongKeTrackingPage />,
        },
        {
          path: "/config-gamestock",
          element: <ConfigGameStockPage />,
        },
        {
          path: "/config-jackpotlive",
          element: <ConfigJackpotLivePage />,
        },
        {
          path: "/config-jackpot",
          element: <ConfigJackpotPage />,
        },
        {
          path: "/config-updategame",
          element: <ConfigUpdateGamePage />,
        },
        {
          path: "/payment-card-nap",
          element: <PaymentCardNapPage />,
        },
        {
          path: "/payment-card-duyet",
          element: <PaymentCardDuyetPage />,
        },
        {
          path: "/payment-card-kho",
          element: <PaymentCardKhoPage />,
        },
        {
          path: "/payment-card-thongkekho",
          element: <PaymentCardThongKeKhoPage />,
        },
        {
          path: "/payment-code-add",
          element: <PaymentCodeAddPage />,
        },
        {
          path: "/payment-code-list",
          element: <PaymentCodeListPage />,
        },
        {
          path: "/payment-code-nap",
          element: <PaymentCodeNapPage />,
        },
        {
          path: "/payment-code-doi",
          element: <PaymentCodeDoiPage />,
        },
        {
          path: "/payment-bank-nap",
          element: <PaymentBankNapPage />,
        },
        {
          path: "/payment-bank-doi",
          element: <PaymentBankDoiPage />,
        },
        {
          path: "/payment-bank-duyet",
          element: <PaymentBankDuyetPage />,
        },
        {
          path: "/payment-momo-nap",
          element: <PaymentMomoNapPage />,
        },
        {
          path: "/payment-momo-doi",
          element: <PaymentMomoDoiPage />,
        },
        {
          path: "/payment-momo-duyet",
          element: <PaymentMomoDuyetPage />,
        },
        {
          path: "/config-payment",
          element: <ConfigPaymentPage />,
        },
        {
          path: "/config-game",
          element: <ConfigGamePage />,
        },
        {
          path: "/config-bank",
          element: <ConfigBankPage />,
        },
        {
          path: "/user-withdraw-sun",
          element: <UserWithDrawSunPage />,
        },
        {
          path: "/user-withdraw-hit",
          element: <UserWithDrawHitPage />,
        },
      ],
    },
    {
      path: "/",
      element: <LoginPage />,
      errorElement: <ErrorPage />,
    },
  ],
  { basename: getBaseUrlPath() }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
reportWebVitals();
